import styled from 'styled-components'
import chroma from 'chroma-js'

import { mq, sizes } from '../../../theme'
import ActionButton from '../../atoms/ActionButton'
import Link from '../../atoms/Link'
import Picture from '../../atoms/Picture'

type CommonProps = {
  $withBackground?: boolean
  $backgroundColor?: string
}

export const DiscoverCard = styled.article<CommonProps>`
  position: relative;
  border-radius: 2rem;
  background-color: ${({ theme, $backgroundColor, $withBackground }) =>
    $withBackground === true
      ? 'transparent'
      : $backgroundColor
      ? $backgroundColor
      : theme.colors.ruby};
`
export const StyledLink = styled(Link)<CommonProps>`
  position: relative;
  z-index: 2;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  height: 100%;
  padding: 3.2rem 2.4rem;
  border-radius: 2rem;
  overflow: hidden;
  color: ${({ theme, $backgroundColor, $withBackground }) =>
    $withBackground === true
      ? theme.colors.pureWhite
      : $backgroundColor && chroma.valid($backgroundColor)
      ? chroma.hex($backgroundColor).luminance() > 0.5
        ? theme.colors.arapawa
        : theme.colors.pureWhite
      : theme.colors.arapawa};
  background-color: ${({ $withBackground, theme }) =>
    $withBackground === true &&
    `${chroma.hex(theme.colors.arapawa).alpha(0.7)}`};
`
export const Title = styled.h3`
  ${({ theme }) => theme.textStyles.titleH4}
  width: 100%;
  text-align: center;
  margin: 0;
  color: currentColor;
  @media ${mq(sizes.tablet)} {
  }
`
export const Action = styled(ActionButton)``
export const Background = styled(Picture)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-radius: 2rem;
  overflow: hidden;
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
