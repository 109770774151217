import React, { FC } from 'react';
import cx from 'classnames';
import { DiscoverCardProps } from '../DiscoverCard';
import * as SC from './styled';
export type DiscoverBlockProps = MinimalSeoBlock & {
  className?: string;
  title?: string;
  items: DiscoverCardProps[];
};
const DiscoverBlock: FC<DiscoverBlockProps> = props => {
  const {
    className,
    htmlTag,
    title,
    htmlTitleTag,
    items
  } = props;
  return items.length > 0 ? <SC.DiscoverBlock className={cx('DiscoverBlock', className)} as={htmlTag}>
      {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
      <SC.Cards>
        {items.map((item, index) => <SC.Card {...item} key={`DiscoverBlock-index-${index}`} />)}
      </SC.Cards>
    </SC.DiscoverBlock> : null;
};
export default DiscoverBlock;