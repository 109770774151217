import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PushHpCardsBlock as PushHpCardsBlockData } from '../../../graphql/generated/api-graphql';
import { BlockProps } from '../props';
import DiscoverBlock from '../../../components/molecules/DiscoverBlock';
import discoverBlockTransform from './transform';
export type PushHpCardsBlockProps = Omit<BlockProps, 'data'> & {
  data: PushHpCardsBlockData;
};
const PushHpCardsBlock: FC<PushHpCardsBlockProps> = ({
  data
}) => {
  const {
    t
  } = useTranslation();
  const props = discoverBlockTransform(t, data);
  return <DiscoverBlock {...props} />;
};
export default PushHpCardsBlock;